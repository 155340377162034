import { FC } from 'react';
import './integration.css';
import { Template } from '../../../services/facebook';

export interface TemplateProps extends Template {
  onRedirect: any;
  onConfigure: any;
  invitationConfig?: any;
}

const TemplateCard: FC<TemplateProps> = ({
  id,
  name,
  status,
  components,
  invitationConfig,
  onConfigure,
  onRedirect
}) => {

  return (
  <div className="integration-card">
    <div className="message-status"><b>{status === "APPROVED" ? "Aprobado" : status === "PENDING" ? "Pendiente" : status === "REJECTED" ? "Rechazado" : status}</b></div>
    <p className="integration-title">{name}</p>
      {components?.map((component,idx) => (
          component.type !== "BUTTONS" 
            ? <p key={idx} className="integration-text">{component.type}: {component?.text}</p> 
            : <div key={idx}>
                <br />
                <p className="integration-text">{component.type}:</p> 
                {component.buttons?.map((button:any,indx) => (
                  <p key={indx} className="integration-text">{button.text}</p>
                ))}
              </div>
      ))}
    <br />
    <p className="integration-text">Configuración de plantilla: <b>{invitationConfig?.type === "invitation" ? "Establecida como invitación ": invitationConfig?.type === "reminder" ? "Establecida como recordatorio" : "Sin uso"}</b></p> 
    <br />
    <div className='integration-actions-container'>
      {!invitationConfig?.integrationTemplateId && <button className="integration-button" onClick={() => onConfigure('invitation', id)}>Usar como invitación</button> }
      {!invitationConfig?.integrationTemplateId &&<button className="integration-button" onClick={() => onConfigure('reminder', id)}>Usar como recordatorio</button>}
      <button className="integration-button" onClick={() => onRedirect('/edit/template', id)}>Editar</button>
    </div>
  </div>
)};

export {
  TemplateCard,
}