import { storage } from '../services';
import { constants, Studies, Surveys } from '../utils';
import { Fields, ServerResponse } from '../utils/models'


// Mock service
async function getStudies (): Promise<Studies> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/study`, fetchOpts);
  const response: ServerResponse<Studies> = await fetchResponse.json();
  return response.data;
}

async function getStudySurveys (studyId: string): Promise<Surveys> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  let filters = {
    studyId
  };
  let URL = `${constants.API_URL}/survey?conditions=${JSON.stringify(filters)}`;
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Studies> = await fetchResponse.json();
  return response.data;
}

async function getFieldsStudy (studyId: string): Promise<Fields> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/study/fields/${studyId}`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Fields> = await fetchResponse.json();
  return response.data;
}

export {
  getStudies,
  getStudySurveys,
  getFieldsStudy
}