import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { selectSliceTemplates } from "../../../store/slices/templates";
import { selectSliceIntegrations } from "../../../store/slices/integrations";
import { Modal } from "../../../common/components/modal";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { integrationService } from "../../../services/integrations";
import { selectSlice } from "../../../store/slices/studies";
import { Fields } from "../../../utils";
import { getFieldsStudy } from "../../../services/studies.services";

interface Button {
  example?: any;
  text: string;
  url?: string;
  type: string;
}

const WhatsAppTemplateForm: React.FC = () => {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [headerText, setHeaderText] = useState<string>("");
  const [newHeaderText, setNewHeaderText] = useState<string>("");
  const [variableInput, setVariableInput] = useState<string>("");
  const [showVariable, setShowVariable] = useState<boolean>(false);
  const [bodyText, setBodyText] = useState<string>("");
  const [newBodyText, setNewBodyText] = useState<string>("");
  const [bodyVariables, setBodyVariables] = useState<string[]>([]);
  const [footer, setFooter] = useState<string>("");
  const [buttons, setButtons] = useState<Button[]>([]);
  const [errorHeader, setErrorHeader] = useState<string | null>(null);
  const [errorBody, setErrorBody] = useState<string | null>(null);
  const [errorButton, setErrorButton] = useState<string | null>(null);
  const [isValidFormatHeader, setIsValidFormatHeader] =
    useState<boolean>(false);
  const [edition, setEdition] = useState<boolean>(false);
  const path = window.location.pathname.includes("edit/template");
  const templates = useAppSelector(selectSliceTemplates);
  const integrations = useAppSelector(selectSliceIntegrations);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [textModal, setTextModal] = useState<string>("");
  const studies = useAppSelector(selectSlice)
  const [fields, setFields] = useState<Fields>([])
  const history = useHistory();

  useEffect(() => {
    async function getFields(){
      if (studies?.selected) {
        const result = await getFieldsStudy(studies.selected._id);
        if(result && result.length > 0) {
          setFields(result);
        }
      }
    }
    getFields();
  }, []);

  useEffect(() => {
    if (path && templates.selected) {
      setEdition(true);
      setId(templates.selected?.id);
      setName(templates.selected?.name);
      const header = templates.selected?.components.find(
        (component) => component.type === "HEADER"
      );
      setHeaderText(header?.text ?? "");
      if (
        header?.example?.header_text &&
        header.example.header_text.length > 0
      ) {
        setVariableInput(header.example.header_text[0]);
        setNewHeaderText(
          replaceVariables(header?.text ?? "", header.example.header_text)
        );
        setShowVariable(true);
        setIsValidFormatHeader(true);
      } else {
        setNewHeaderText(header?.text ?? "");
        setVariableInput("");
      }
      const body = templates.selected?.components.find(
        (component) => component.type === "BODY"
      );
      setBodyText(body?.text ?? "");
      if (body?.example?.body_text && body.example.body_text.length > 0) {
        setBodyVariables(body.example.body_text[0]);
        handleTextChange(body?.text ?? "", body.example.body_text[0]);
      } else {
        setNewBodyText(body?.text ?? "");
        setBodyVariables([]);
      }
      setFooter(
        templates.selected?.components.find(
          (component) => component.type === "FOOTER"
        )?.text ?? ""
      );
      setButtons(
        templates.selected?.components.find(
          (component) => component.type === "BUTTONS"
        )?.buttons ?? []
      );
    }
  }, [path, templates.selected]);

  const handleAddButton = () => {
    if (buttons.length < 2) {
      setButtons([...buttons, { text: "", type: "QUICK_REPLY" }]);
    }
  };

  const handleButtonChange = (
    index: number,
    field: "text" | "url" | "type" | "example",
    value: string
  ) => {
    let validate = false;
    const updatedButtons = buttons.map((button, i) => {
      if (i === index) {
        if (field === "url") validate = validateText(value, "button");
        const { example, ...res } = button;
        if (field === "example" && button.url) {
          return {
            ...res,
            [field]: [value]
          };
        }
        return {
          ...res,
          [field]: value,
        };
      }
      return button;
    });

    setButtons(updatedButtons);
  };

  const addVariableToHeader = () => {
    if (!showVariable) {
      setHeaderText((prev) => `${prev} {{1}}`);
      setVariableInput("");
      validateText(`${bodyText} {{1}}`, "header");
      setShowVariable(true);
    }
  };

  const addVariableBody = () => {
    const newIndex = bodyVariables.length + 1;
    if (bodyVariables.length < 9) {
      setBodyVariables((prev) => [...prev, ""]);
      setBodyText((prev) => `${prev} {{${newIndex}}}`);
      validateText(`${bodyText} {{${newIndex}}}`, "body");
    }
  };

  const addVariableToButton = (index: number) => {
    const updatedButtons = buttons.map((button, i) => {
      if (i === index && !button.example) {
        if(button.url === "" || button.url === undefined) {
          setErrorButton(
            "Por favor agrega una Url al botón para asignarle un token de invitación"
          );
          return button;
        }
        return {
          ...button,
          url: button.url ? `${button.url}{{1}}` : '{{1}}',
          example: [button.url+'InvitationToken'],
        };
      }
      return button;
    });

    setButtons(updatedButtons);
  };

  const handleVariableChange = (index: number, value: string) => {
    const updatedvars = [...bodyVariables];
    updatedvars[index] = value;
    setBodyVariables(updatedvars);
    handleTextChange(bodyText, updatedvars);
  };

  const removeButton = (index: number) => {
    const updatedButtons = buttons.filter((_, i) => i !== index);
    setButtons(updatedButtons);
  };

  const handleTemplate = async () => {
    setLoading(true);
    if (!errorHeader && !errorBody && !errorButton && bodyText) {
      let data: any = {
        components: [
          {
            type: "BODY",
            text: bodyText,
          },
        ],
      };
      if (bodyVariables.length > 0) {
        data.components[0].example = {
          body_text: [bodyVariables],
        };
      }
      if (headerText !== "") {
        data.components.unshift({
          type: "HEADER",
          format: "TEXT",
          text: headerText,
        });
        if (variableInput !== "")
          data.components[0].example = {
            header_text: [variableInput],
          };
      }
      if (footer !== "") {
        data.components.push({
          type: "FOOTER",
          text: footer,
        });
      }
      if (buttons.length > 0) {
        data.components.push({
          type: "BUTTONS",
          buttons: buttons.map((button) => {
            const parameters: any = { type: button.type, text: button.text };
            if (button.url) parameters.url = button.url;
            if (button.example) parameters.example = button.example;
            return parameters;
          }),
        });
      }
      const newTemplate = {
        name: name,
        category: "UTILITY",
        allow_category_change: true,
        language: "es",
        components: data.components,
      };

      try {
        setTextModal("Procesando...");
        setOpenModal(true);
        if (edition && integrations.selected?._id && id) {
          const response = await integrationService.UpdateMessageTemplate(
            integrations.selected._id,
            id,
            data
          );
          if (response?.success) {
            setLoading(false);
            setTextModal("Plantilla actualizada correctamente");
          } else {
            setTextModal(
              `Error al actualizar plantilla ${response}`
            );
            setLoading(false);
          }
        } else if (
          !edition &&
          name &&
          integrations.selected?._id
        ) {
          const response = await integrationService.CreateMessageTemplate(
            integrations.selected._id,
            newTemplate
          );
          if (response?.status) {
            setLoading(false);
            setTextModal(
              `Plantilla creada correctamente con estatus ${
                response.status === "APPROVED"
                  ? "Aprobado"
                  : response.status === "PENDING"
                  ? "Pendiente"
                  : response.status === "REJECTED"
                  ? "Rechazado"
                  : response.status
              }`
            );
          } else {
            setTextModal(
              `Error al crear plantilla ${response}`
            );
            setLoading(false);
          }
        } else {
          setTextModal(
            "Para continuar es necesario que la plantilla tenga un nombre"
          );
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setTextModal("Error: " + error);
      }
    } else {
      setOpenModal(true);
      setTextModal(
        "Para continuar es necesario que la plantilla tenga un mensaje"
      );
      setLoading(false);
    }
  };

  const removeVar = (index: number) => {
    if (index > -1) {
      const updatedVars = bodyVariables.filter((_, i) => i !== index);
      setBodyVariables(updatedVars);
    }
  };

  const validateText = (text: string, type: string) => {
    const invalidFormat = /{{[1-9]}/g;
    const validFormat = /{{[1-9]}}/g;
    const combinedFormat = /{{[1-9]}}|{{[1-9]}/g;
    const matches = [];
    let match;

    const invalidMatches = text.match(invalidFormat);
    const validMatches = text.match(validFormat);

    while ((match = combinedFormat.exec(text)) !== null) {
      matches.push(match[0]);
    }

    if (
      invalidMatches &&
      (!validMatches || invalidMatches.length > validMatches.length)
    ) {
      if (type === "body") {
        setErrorBody(
          "La plantilla contiene parámetros variables con formato incorrecto. Los parámetros variables deben ser números enteros con dos conjuntos de llaves (por ejemplo, {{1}}, {{2}})."
        );
        const firstInvalidIndex = matches.findIndex((m) =>
          /^{{[1-9]}$/.test(m)
        );
        removeVar(firstInvalidIndex);
      } else if (type === "button") {
        setErrorButton(
          "La plantilla contiene parámetros variables con formato incorrecto. Los parámetros variables deben ser números enteros con dos conjuntos de llaves (por ejemplo, {{1}})."
        );
      } else {
        setErrorHeader(
          "La plantilla contiene parámetros variables con formato incorrecto. Los parámetros variables deben ser números enteros con dos conjuntos de llaves (por ejemplo, {{1}})."
        );
        setShowVariable(false);
        setVariableInput("");
      }
      return false;
    } else {
      if (type === "body") {
        setErrorBody(null);
      } else if (type === "button") {
        setErrorButton(null);
      } else {
        setErrorHeader(null);
        setIsValidFormatHeader(validMatches ? validMatches.length > 0 : false);
        if (validMatches && validMatches.length > 0) setShowVariable(true);
      }
      return true;
    }
  };

  const handleChangeHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setHeaderText(value);
    validateText(value, "header");
    setNewHeaderText(replaceVariables(value, [variableInput]));
  };

  const handlebodyText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setBodyText(value);
    handleTextChange(value, bodyVariables);
    validateText(value, "body");
  };

  const replaceVariables = (text: string, array: string[]): string => {
    return text.replace(/{{(\d)}}/g, (match, p1) => {
      const index = parseInt(p1, 10) - 1;
      return array[index] || match;
    });
  };

  const handleTextChange = (text: string, array: string[]) => {
    setNewBodyText(replaceVariables(text, array));
  };

  function clickModal() {
    if (textModal.includes("correctamente")) {
      history.push("/templates");
    } else {
      setOpenModal(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
        background: "#fff",
      }}
    >
      <Modal
        open={openModal}
        showLoadingAnimation={loading}
        text={textModal}
        onClick={() => clickModal()}
      />
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          maxHeight: "calc(100vh - 3.5rem)",
        }}
      >
        <h2>Crear Plantilla de WhatsApp</h2>
        <br />
        <div>
          <label className="input-label">Nombre de la plantilla</label>
          <input
            type="text"
            placeholder="Nombre"
            className="text-input w-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: "100%", marginBottom: "10px" }}
          />
        </div>

        <div>
          <label className="input-label">Texto del Encabezado Opcional</label>
          <input
            type="text"
            className="text-input w-input"
            placeholder="Encabezado"
            value={headerText}
            onChange={handleChangeHeader}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          {errorHeader && (
            <p style={{ color: "red", fontSize: "14px", marginBottom: "12px" }}>
              {errorHeader}
            </p>
          )}
        </div>

        <div>
          {showVariable && isValidFormatHeader && !errorHeader &&(
            <div>
              <label className="input-label">Variable del Encabezado</label>
              <select className="text-input w-input" value={variableInput} onChange={(e) => {
                setVariableInput(e.target.value); 
                setNewHeaderText(
                    replaceVariables(headerText, [e.target.value])
                  );
                }} 
                style={{ width: "100%", marginBottom: "10px" }}>
                <option value="">Selecciona una opción</option>
                {fields?.map((field) => (
                  <option key={field._id} value={field.display.es}>
                    {field.display.es}
                  </option>
                ))}
              </select>
            </div>
          )}
          <button onClick={addVariableToHeader} className="form-submit-button">
            Agregar Variable Encabezado
          </button>
        </div>

        <br />
        <br />

        <div>
          <label className="input-label">Cuerpo del Mensaje</label>
          <textarea
            placeholder="Texto"
            className="text-input w-input"
            value={bodyText}
            onChange={handlebodyText}
            style={{ width: "100%", marginBottom: "10px", minHeight: "250px" }}
          />
          {errorBody && (
            <p style={{ color: "red", fontSize: "14px", marginBottom: "12px" }}>
              {errorBody}
            </p>
          )}
        </div>

        <div>
          <button onClick={addVariableBody} className="form-submit-button">
            Agregar Variable
          </button>
          <br />
          {bodyVariables.map((variable, index) => (
            <div>
              <label className="input-label">Variable {index + 1}</label>
              <select 
                className="text-input w-input" 
                value={variable}
                onChange={(e) => handleVariableChange(index, e.target.value)}
                style={{ width: "100%", marginBottom: "10px" }}>
                <option value="">Selecciona una opción</option>
                {fields?.map((field) => (
                  <option key={field._id} value={field.display.es}>
                    {field.display.es}
                  </option>
                ))}
              </select>
              <br />
            </div>
          ))}
        </div>

        <br />

        <div>
          <label className="input-label">Pie de página Opcional</label>
          <input
            type="text"
            placeholder="Texto"
            className="text-input w-input"
            value={footer}
            onChange={(e) => setFooter(e.target.value)}
            style={{ width: "100%", marginBottom: "10px" }}
          />
        </div>

        <br />

        <div>
          <button onClick={handleAddButton} className="form-submit-button">
            Agregar Botón
          </button>
          <br />
          {buttons.map((button, index) => (
            <div>
              <div
                key={index}
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  borderRadius: "6px",
                  paddingTop: "14px",
                  position: "relative",
                }}
              >
                <a
                  style={{
                    position: "absolute",
                    right: "8px",
                    top: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() => removeButton(index)}
                >
                  X
                </a>
                <label className="input-label">Tipo</label>
                <select
                  className="text-input w-input"
                  style={{ width: "100%", marginBottom: "10px" }}
                  value={button.type}
                  onChange={(e) =>
                    handleButtonChange(index, "type", e.target.value)
                  }
                >
                  <option value="QUICK_REPLY">Respuesta Rapida</option>
                  <option value="URL">Url</option>
                </select>
                <label className="input-label">Texto del Botón</label>
                <input
                  type="text"
                  placeholder="Texto"
                  className="text-input w-input"
                  value={button.text}
                  onChange={(e) =>
                    handleButtonChange(index, "text", e.target.value)
                  }
                  style={{ width: "100%", marginBottom: "10px" }}
                />
                {button.type === "URL" && (
                  <div>
                    <label className="input-label">URL del Botón</label>
                    <input
                      type="text"
                      placeholder="URL"
                      className="text-input w-input"
                      value={button.url}
                      onChange={(e) =>
                        handleButtonChange(index, "url", e.target.value)
                      }
                      style={{ width: "100%", marginBottom: "10px" }}
                    />
                    {button?.example?.length > 0 && (
                      <div>
                        <label className="input-label">
                          URL de muestra
                        </label>
                        <input
                          type="text"
                          placeholder="Ingresa la url completa de ejemplo"
                          className="text-input w-input"
                          readOnly={true}
                          value={button.example}
                          style={{ width: "100%", marginBottom: "10px" }}
                        />
                      </div>
                    )}
                    <button
                      onClick={() => addVariableToButton(index)}
                      className="form-submit-button"
                    >
                      Agregar Token de Invitación URL
                    </button>
                  </div>
                )}
                <br />
              </div>
              <br />
            </div>
          ))}
          {errorButton && (
            <p style={{ color: "red", fontSize: "14px", marginBottom: "12px" }}>
              {errorButton}
            </p>
          )}
        </div>

        <button
          onClick={handleTemplate}
          className="form-submit-button"
          style={{ marginBottom: "24px" }}
        >
          {edition ? "Editar plantilla" : "Crear Plantilla"}
        </button>
      </div>

      <div
        style={{
          flex: 1,
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "10px",
          marginLeft: "48px",
          backgroundColor: "#f9f9f9",
          maxHeight: "640px",
          marginTop: "80px",
        }}
      >
        <h3>Vista Previa de WhatsApp</h3>
        <div
          style={{
            padding: "10px",
            backgroundColor: "#eaeaea",
            borderRadius: "5px",
            minHeight: "250px",
          }}
        >
          <div className="current-date" data-testid="datearea">
            {moment().format("LL")}
          </div>
          <div style={{ margin: "12px", background: "white", padding:'12px'}}>
            <div className="content-list">
              <div className="message-area">
                <strong>{newHeaderText}</strong>

                <div style={{ whiteSpace: "pre-line" }}>{newBodyText}</div>
                {footer && (
                  <div>
                    <br />
                  </div>
                )}
                <p style={{ color: "gray", fontSize: "14px" }}>{footer}</p>
                {buttons.length > 0 && (
                  <div>
                    <hr className="separator"></hr>
                  </div>
                )}
                {buttons.map((button, index) => (
                  <div
                    key={index}
                    style={{ marginTop: "5px", textAlign: "center" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                      className="icon-extlink"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8.667v4A1.334 1.334 0 0 1 10.667 14H3.333A1.334 1.334 0 0 1 2 12.667V5.333A1.333 1.333 0 0 1 3.333 4h4M10 2h4v4M6.667 9.333 14 2"
                      ></path>
                    </svg>
                    <a
                      href={button.example ? button.example : button.url}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#007BFF" }}
                    >
                      {button.text}
                    </a>
                  </div>
                ))}
              </div>
              <div style={{ marginTop: "8px" }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppTemplateForm;
