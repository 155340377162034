import { storage } from '../../services';
import { constants} from '../../utils';
import { ServerResponse } from '../../utils/models'
import { InvitationConfigs } from './invitation-config.model';


async function getInvitationConfigs (conditions?: any): Promise<InvitationConfigs> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/invitation-config`;
  if (conditions) {
    URL += `?conditions=${JSON.stringify(conditions)}`
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<InvitationConfigs> = await fetchResponse.json();
  return response.data;
}

export {
  getInvitationConfigs
}