import { FC, useEffect, useState } from 'react';
import './widgets/integration.css';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components';
import { Templates } from '../../services/facebook';
import { translateError } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSliceIntegrations } from '../../store/slices/integrations';
import { TemplateCard } from './widgets/template-card';
import { useHistory } from 'react-router-dom';
import { setAvailableTemplates, setTemplate } from '../../store/slices/templates';
import { integrationService } from '../../services/integrations';
import { InvitationConfigs, invitationConfigService } from '../../services/invitation-config';
import { selectSlice } from '../../store/slices/studies';
import { Modal } from '../../common/components/modal';

export interface TemplateExplorerProps  {
}

const TemplatesExplorer: FC<TemplateExplorerProps>  = () => {
  const [templates, setTemplates] = useState<Templates>([]);
  const [loading, setLoading] = useState(true);
  const integrations = useAppSelector(selectSliceIntegrations);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const studies = useAppSelector(selectSlice)
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [textModal, setTextModal] = useState<string>("");
  const [typeTemplate, setTypeTemplate] = useState<string>("");
  const [idTemplate, setIdTemplate] = useState<string>("");
  const [invitationConfigs, setIvitationConfigs] = useState<InvitationConfigs>([])

  async function loadTemplates(): Promise<void> {
    setLoading(true);
    try {
      if(integrations.selected?.userId){
      const templates = await integrationService.getMessageTemplates(
        integrations.selected?._id
      );
      if (templates) {
        setTemplates(templates);
        dispatch(setAvailableTemplates(templates));
      }
    }
    } catch (err) {
      const error = translateError(err);
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  async function getConfigs(){
    if (studies?.selected) {
      const result = await invitationConfigService.getInvitationConfigs({surveyId: studies.selected.surveys[0], service: 'whatsapp'});
      if(result && result.length > 0) {
        setIvitationConfigs(result);
      }
    }
  }

  useEffect(() => {
    loadTemplates();
    getConfigs();
  }, [integrations.selected]);

  function onRedirect (path: string, templateId: string ) {
    dispatch(setTemplate(templateId));
    history.push(path);
  }

  function onConfigure (type: string, id: string ) {
    setTextModal("¿Estás seguro de que deseas utilizar esta plantilla? Ten en cuenta que, si hay configuraciones previas, serán eliminadas y reemplazadas por las de la nueva plantilla");
    setOpenModal(true);
    setIdTemplate(id);
    setTypeTemplate(type)
  }

  async function clickModal() {
    setTextModal("Procesando...");
    if (textModal.includes("correctamente")) {
      getConfigs();
      setOpenModal(false);
      setTextModal("");
    } else if(textModal.includes("deseas utilizar esta plantilla")) {
      if(textModal) {
        try {
          setLoading(true);
          const template =  templates?.find(item => item.id === idTemplate); 
          const response = await integrationService.ConfigTemplateWhatsapp({studyId: studies?.selected?._id, type: typeTemplate, ...template});
          if(response.data){
            setTextModal("Configuración actualizada correctamente");
          }
          if(response.message?.es) {
            setTextModal(`Error: ${response.message?.es}`);
          }
        } catch (error) {
          setTextModal(
            `Error: ${error}`
          );
        }
        finally {
          setLoading(false);
        }
      }
    } else {
      setOpenModal(false);
    }
  }

  async function cancelModal () {
    setTextModal("");
    setOpenModal(false);
    setIdTemplate("");
    setTypeTemplate("")
  }
  
  return (
    <MainLayout>
    <PageHeader
      title='Plantillas'
      showFilters={false}
      showRangeInput={false}
      showMainFilters={false}
      actionLabel='Crear nueva plantilla'
      action={() => history.push('/create/template')}
    />
    <Modal
        open={openModal}
        showLoadingAnimation={loading}
        text={textModal}
        onClick={() => clickModal()}
        showCancel={true}
        onCancel={()=> cancelModal()}
      />
    <div className="integration-explorer">
      <div className="integration-list">
        {templates?.map((template) => (
          <TemplateCard
            key={template.id}
            {...template}
            onRedirect={onRedirect}
            onConfigure={onConfigure}
            invitationConfig={(invitationConfigs?.find(item => item.integrationTemplateId === template.id))  }
          />
        ))}
        {loading && <p>Cargando datos</p>}
        {templates && templates?.length < 1 && <p style={{fontSize: '18px'}}>No existen plantillas creadas</p>} 
      </div>
    </div>
  </MainLayout>
  );
}

export { TemplatesExplorer }
export default TemplatesExplorer;